import { SideNavigationProps } from "@amzn/awsui-components-react"
import { isConfigOverrideSupported } from "../features/features";
import { Broker } from "@amzn/amazonmq-opsconsole-client";

export function rabbitNavItems(broker: Broker): SideNavigationProps.Item[] {
    const brokerId = broker.id;
    let brokerItems: SideNavigationProps.Item[]=[
        { type: 'link', text: `Broker Details`, href: `/broker/${brokerId}/details` },
        { type: 'link', text: `Service Metrics`, href: `/broker/${brokerId}/service-metrics` },
        { type: 'link', text: `Broker Environment`, href: `/broker/${brokerId}/environment`},
        { type: 'link', text: `Vhosts`, href: `/broker/${brokerId}/vhosts`},
        { type: 'link', text: `Cluster Status`, href: `/broker/${brokerId}/cluster-status` },
        { type: 'link', text: `Policies`, href: `/broker/${brokerId}/policies` },
        { type: 'link', text: `Broker Timeline`, href: `/broker/${brokerId}/broker-timeline`},
        { type: 'link', text: `Autoscaling Group`, href: `/broker/${brokerId}/asg`},
        { type: 'link', text: `Logs`, href: `/broker/${brokerId}/logs` },
        { type: 'link', text: `Failover History`, href: `/broker/${brokerId}/failover-history` },
    ];
    
    let queuesItems: SideNavigationProps.Item[]=[
        { type: 'link', text: `Queues`, href: `/broker/${brokerId}/queues`},
        { type: 'link', text: `Describe Queue`, href: `/broker/${brokerId}/queue`},
        { type: 'link', text: `Quorum Status`, href: `/broker/${brokerId}/quorum-status`},
        { type: 'link', text: `Unresponsive Queues`, href: `/broker/${brokerId}/unresponsive-queues`},
    ];

    let operationsItems: SideNavigationProps.Item[] = [
        { type: 'link', text: `SIM Issues`, href: `/broker/${brokerId}/sim-issues` },
        { type: 'link', text: `Reachout Templates`, href: `/broker/${brokerId}/reachout-templates`},
        { type: 'link', text: `Command Templates`, href: `/broker/${brokerId}/command-templates` },
    ];

    let workflowsItems: SideNavigationProps.Item[] = [
        { type: 'link', text: `Workflows`, href: `/broker/${brokerId}/workflows` },
        { type: 'link', text: `HOTW Executions`, href: `/broker/${brokerId}/hotw-executions` },
        { type: 'link', text: `Code Deploy`, href: `/broker/${brokerId}/codedeploy` },
        { type: 'link', text: `Event History`, href: `/broker/${brokerId}/event-history` },
    ];

    let otherItems: SideNavigationProps.Item[] = [
        { type: 'link', text: `Service Metrics (Old)`, href: `/broker/${brokerId}/service-metrics-old` },
        { type: 'link', text: `Logs`, href: `/broker/${brokerId}/logs-old` },
    ];

    if (isConfigOverrideSupported(broker)) {
        otherItems.push(
            { type: 'link', text: `RabbitMQ Config Override`, href: `/broker/${brokerId}/config-override` }
        )
    }

    let navItems: SideNavigationProps.Item[] = [
        { type: 'section', text: 'Broker', items: brokerItems},
        { type: 'divider' },
        { type: 'section', text: 'Queues', items: queuesItems },
        { type: 'divider' },
        { type: 'section', text: 'Workflows', items: workflowsItems },
        { type: 'divider' },
        { type: 'section', text: 'Operations', items: operationsItems },
        { type: 'divider' },
        { type: 'section', text: 'Other', items: otherItems },
        { type: 'divider' },
        { type: 'link', text: `Runbook`, external: true, href: `https://w.amazon.com/bin/view/AmazonMQ/Ops/Runbook` },
    ];

    return navItems;
}